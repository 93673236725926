import React, { useEffect, useState } from 'react';
import axios from 'axios';

const VerifyTest: React.FC = () => {
  const [downtime, setDowntime] = useState<number>(0);
  const [isApiDown, setIsApiDown] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<number | null>(null);

  const checkApi = async () => {
    try {
      await axios.get('https://pmsdev.finexo.in/api/client/pm2test', { timeout: 100 });
      
      if (isApiDown) {
        setIsApiDown(false);
        setStartTime(null);
      }
    } catch (error) {
      if (!isApiDown) {
        setIsApiDown(true);
        setStartTime(Date.now());
      }
    }
  };

  useEffect(() => {
    // Check API status every 100ms
    const intervalId = setInterval(checkApi, 100);

    // Update downtime counter if API is down
    const timerId = setInterval(() => {
      if (isApiDown && startTime) {
        const elapsedMs = Date.now() - startTime;
        setDowntime(elapsedMs);
      }
    }, 1000);

    // Cleanup intervals on component unmount
    return () => {
      clearInterval(intervalId);
      clearInterval(timerId);
    };
  }, [isApiDown, startTime]);

  return (
    <div>
      <h1>API Status Monitor</h1>
      <div>
        Status: {isApiDown ? 'API is down' : 'API is up'}
      </div>
      <div>
        Downtime: {downtime} ms
      </div>
    </div>
  );
};

export default VerifyTest;
